<template>
  <div class="is-flex has-background-white-bis p-05">
    <div class="column">
      <text-input required
                  v-model="status.name"
                  placeholder="Enter status name">
        Status Name
      </text-input>
    </div>
    <div class="column">
      <text-input v-model="status.description"
                  placeholder="Enter status description">
        Status Description <span class="has-text-grey">(optional)</span>
      </text-input>
    </div>
    <!--    Options-->
    <div class="column">
      <field-label class="has-text-weight-bold" required>Options</field-label>
      <dynamic-option v-for="(option, index) in status.options"
                      :option="option"
                      :index="index"
                      :key="index"
                      @addOption="addOption"
                      @removeOption="removeOption"
      />
      <div class="is-flex align-items-center justify-center">
        <action-button @click="addOption"
                       data-tooltip="Add Option"
                       class="is-rounded has-tooltip-bottom hover-success">
          <icon icon="plus" class="has-text-grey"/>
        </action-button>
      </div>
    </div>
    <!--    Buttons-->
    <div class="column is-narrow">
      <div class="is-flex mt-2">
        <action-button :disabled="!canSave"
                       @click="addStatus"
                       data-tooltip="Add Status"
                       class="is-rounded has-tooltip-bottom is-success mr-1">
          <icon icon="check"/>
        </action-button>
        <action-button :working="working"
                       class="is-rounded is-danger"
                       @click="clearStatus">
          <icon icon="trash"/>
        </action-button>
      </div>
    </div>
  </div>
</template>
<script>

import DynamicOption from "@/views/asset-type/partials/DynamicOption.vue";
import DynamicStatusOption from "@/models/DynamicStatusOption";
import DynamicStatus from "@/models/DynamicStatus";

export default {
  components: {DynamicOption},

  data: () => ({
    working: false,
    status: new DynamicStatus
  }),

  methods: {
    clearStatus() {
      this.status = new DynamicStatus
    },
    addStatus() {
      if (this.optionValuesAreUnique === false) return this.$toast.warning("All 'Options' values are not unique. Please use unique values for each option")
      this.$emit('addStatus', this.status)
      this.clearStatus()
    },
    addOption() {
      this.status.options.push(new DynamicStatusOption)
    },
    removeOption(index, option) {
      if (this.status.options.length > 1) {
        return this.status.options.splice(index, 1)
      }
      option.value = ''
    },
  },

  computed: {
    canSave() {
      return this.status.name.length > 0 &&
          this.status.options?.length > 0 &&
          this.status.options.every(option => option.value !== '')
    },
    optionValuesAreUnique() {
      let values = this.status.options.map(option => option.value)
      return new Set(values).size === values.length
    }

  },
};
</script>
