<template>
  <loader v-bind="{ loading }">
    <div class="columns">
      <div class="column">
        <summary-item
            class="box"
            icon="coins"
            horizontal
            :title="Naming.Assets.toLowerCase()"
            :value="overview.assets | abbreviateCount"/>
      </div>
      <div class="column">
        <summary-item
            class="box"
            icon="times-circle"
            horizontal
            :title="`defective ${Naming.Assets.toLowerCase()}`"
            :value="overview.defective_assets | abbreviateCount"/>
      </div>
      <div class="column">
        <summary-item
            class="box"
            icon="check-circle"
            horizontal
            :title="`ok ${Naming.Assets.toLowerCase()}`"
            :value="overview.ok_assets | abbreviateCount"/>
      </div>
    </div>

    <div class="box">
      <h4 class="is-size-4">{{ Naming.Checklists }}</h4>
      <table class="table is-striped is-fullwidth">
        <thead>
        <tr>
          <th>{{ Naming.Checklist }}</th>
          <th>{{ Naming.Checks }}</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="checklist in assetType.checklists" :key="checklist.id">
          <td>{{ checklist.name }}</td>
          <td>{{ checklist.checks.length }}</td>
        </tr>
        </tbody>
      </table>
    </div>

    <div class="box">
      <h4 class="is-size-4">{{ Naming.AssetGroups }}</h4>
      <table class="table is-striped is-fullwidth">
        <thead>
        <tr>
          <th>{{ Naming.AssetGroup }}</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="zoneType in assetType.zone_types" :key="zoneType.id">
          <td>{{ zoneType.description }}</td>
        </tr>
        </tbody>
      </table>
    </div>

    <div class="box" v-if="isCompanyPrimaryUser">
      <h4 class="is-size-4">{{ Naming.AssetType }} Configuration</h4>
      <table class="table is-striped is-fullwidth">
        <thead>
        <tr>
          <th>Enforce {{ Naming.Checklist }} Order</th>
          <th>Allow Barcodeless {{ Naming.Inspections }}</th>
        </tr>

        </thead>
        <tbody>
        <tr>
          <td>{{ assetType.enforce_checklist_order === true ? 'Yes' : 'No' }}</td>
          <td>{{ allowsBarcodelessInspections }}</td>
        </tr>
        </tbody>
      </table>
    </div>
  </loader>
</template>
<script>
import {mapGetters} from 'vuex';

export default {

  data: () => ({
    loading: true
  }),

  created() {
    Promise.all([
      this.$store.dispatch('assetType/loadOverview', this.$route.params.assetType)
    ]).then(() => this.loading = false)
  },

  computed: {
    ...mapGetters('assetType', [
      'overview',
      'assetType'
    ]),
    ...mapGetters('company', ['company']),
    allowsBarcodelessInspections() {
      return (this.company.allow_barcodeless_inspections && this.assetType.allow_barcodeless_inspections) ? 'Yes' : 'No'
    },

  }

}
</script>