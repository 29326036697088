<template>
  <loader v-bind="{ loading }">
    <div class="box p-2 has-background-white">

      <div class="is-flex align-items-center justify-between mb-1">
        <div class="is-flex align-items-center">
          <h3 class="is-size-4">Dynamic Statuses</h3>
          <icon
              icon="info-circle"
              class="ml-1 mb-1 is-small has-text-grey-lighter has-tooltip-right has-tooltip-multiline"
              :data-tooltip="statusesInfo"/>
        </div>
        <div class="is-flex justify-end align-items-center">
          <action-button :disabled="addStatusLocked"
                         @click="addNewStatus"
                         left-icon="plus"
                         class="is-rounded is-primary">
            Add New Status
          </action-button>
        </div>
      </div>

      <table class="table is-fullwidth mb-2">
        <thead>
        <tr>
          <th>Name</th>
          <th>Description</th>
          <th>Options</th>
          <th class="is-narrow">Edit Status</th>
        </tr>
        </thead>
        <tbody>
        <dynamic-status
            v-for="status in dynamicStatuses"
            :status="status"
            :locked="assetType.locked"
            :key="status.id"
            @reload="loadStatuses"
            @cancel="cancelEditing"
            @editingField="disableEditing"/>
        <dynamic-status v-show="addingStatus" ref="new" @reload="reloadStatuses" @cancel="cancelEditing" />
        </tbody>
      </table>
      <p class="has-text-grey-light mb-2" v-if="dynamicStatuses.length < 1 && addingStatus === false">
        There are no dynamic statuses for this {{ Naming.AssetType }} yet. <span @click="addNewStatus"
                                                                                 class="has-text-weight-bold hover-bold">Add A New Status</span>
      </p>
    </div>
  </loader>
</template>

<script>
import {mapGetters} from 'vuex'
import DynamicStatus from "@/views/asset-type/partials/DynamicStatus.vue";

export default {
  name: 'asset-type-dynamic-statuses',

  components: {
    DynamicStatus,
  },

  data: () => ({
    loading: true,
    addingStatus: false,
    canAddNew: true,
    saved: false,
  }),

  created() {
    this.loadStatuses()
  },

  methods: {
    disableEditing(statusId) {
      this.canAddNew = false
      this.$store.commit('dynamicStatus/disableEditing', statusId)
    },
    cancelEditing(){
      this.$store.commit('dynamicStatus/enableEditing')
      this.addingStatus = false
      this.canAddNew = true
    },
    async loadStatuses() {
      this.loading = true
      await this.$store.dispatch('dynamicStatus/loadDynamicStatuses', this.$route.params.assetType).then(() => {
        this.addingStatus = false
        this.canAddNew = true
        this.loading = false
      })
    },
    async reloadStatuses() {
      this.$store.commit('dynamicStatus/enableEditing')
      await this.$store.dispatch('dynamicStatus/loadDynamicStatuses', this.$route.params.assetType).then(() => {
        this.addingStatus = false
        this.canAddNew = true
      })
    },
    addNewStatus() {
      this.disableEditing(null)
      this.addingStatus = true
      this.canAddNew = false
      this.$refs.new.$emit('adding-status');
    },
  },

  computed: {
    ...mapGetters('assetType', ['assetType']),
    ...mapGetters('dynamicStatus', ['dynamicStatuses']),
    statusesInfo() {
      return this.Convert(this.$lang.assetType.dynamicStatuses) ?? ''
    },
    addStatusLocked(){
      return !this.canAddNew || this.assetType.locked
    }
  },
}
</script>