<template>
  <page icon="coins" :title="`${Naming.AssetType}: ` + assetType.name" v-bind="{ loading }">

    <template #tools>
      <div class="tabs is-toggle">
        <ul>
          <action-button
              v-if="isSunSlotsAdmin"
              @click="requestAssetHistoryReport"
              :working="requestingAssetHistoryReport"
              left-icon="download"
              class="button is-primary mr-1">
            Request Asset History Report
          </action-button>
          <router-link exact tag="li" :to="{ name: 'asset-type-overview'}">
            <a>Overview</a>
          </router-link>
          <router-link exact tag="li" :to="{ name: 'asset-type-assets'}">
            <a>{{ Naming.Assets }}</a>
          </router-link>
          <router-link exact tag="li" :to="{ name: 'asset-type-custom-fields'}">
            <a>{{ Naming.CustomFields }}</a>
          </router-link>
          <router-link exact tag="li"
                       :to="{ name: 'asset-type-dynamic-statuses'}"
                       v-if="$root.hasAbility('manage-dynamic-statuses', 'App\\Models\\DynamicStatus')"
          >
            <a>Dynamic Statuses</a>
          </router-link>
          <router-link exact tag="li" :to="{ name: 'asset-type-edit'}">
            <a>Edit</a>
          </router-link>
        </ul>
      </div>
    </template>

    <router-view/>

  </page>
</template>
<script>
import {mapGetters, mapState} from 'vuex'

export default {
  data: () => ({
    loading: true,
    requestingAssetHistoryReport: false
  }),

  created() {
    Promise.all([
      this.$store.dispatch('assetType/loadAssetType', this.$route.params.assetType),
    ]).then(() => this.loading = false)
  },

  computed: {
    ...mapGetters('assetType', ['assetType']),
    ...mapGetters('auth', ['isAdmin']),
    ...mapState('company', ['company']),
    isSunSlotsAdmin(){
      return this.assetType.job_type_template_id === null
          && this.isAdmin
          && (this.company.id === 274 || this.company.id === 258)
    }
  },

  methods: {
    async requestAssetHistoryReport(){
      this.requestingAssetHistoryReport = true
      await this.$store.dispatch('assetType/requestAssetHistoryReport').then((response) => {
        this.$toast.success(response.data)
        this.requestingAssetHistoryReport = false
      }).catch((exception)=>{
        this.$toast.warning("Report generation has failed. Please try again or contact support.")
        this.requestingAssetHistoryReport = false
      })
    }
  }
}
</script>