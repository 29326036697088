<template>
  <loader v-bind="{ loading }" :text="`Loading ${Naming.Asset} Overview`">
    <div class="box">
      <div class="columns has-border-thin-grey">
        <div class="column is-5">
          <div class="is-flex align-start mt-1 ml-1">
            <div class="has-text-centered">
              <qr-code style="width: 180px;" :text="asset.barcode.data"/>
              <small>{{ asset.barcode.data }}</small>
            </div>
            <div class="ml-1">
              <h1 class="is-3 title is-marginless has-text-weight-medium">
                <span class="has-text-weight-normal">{{ asset.code || asset.id }}</span>
              </h1>
              <div v-if="assetCustomFields" class="">
                <p v-for="(field, index) in assetCustomFields" :key="index" class="has-text-grey">
                  {{ field.label }}:
                  <span class="has-text-weight-medium">{{ field.value ? field.value : '--' }}</span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="column is-2 mt-1 mb-1 has-text-centered is-flex-column align-center dashboard-box">
          <div class="overview-heading">
            <div>
              {{ Naming.Asset }} Risk
            </div>
            <current-risk class="is-size-6 is-marginless" :risk="asset.risk"/>
            <p>{{ asset.risk.level }}</p>
          </div>
          <div class="overview-heading asset-status">
            <div>
              {{ Naming.Asset }} Status
            </div>
            <current-status
                class="is-size-6"
                v-if="asset.current_status.value"
                :status="asset.current_status"/>
            <p v-else>Unavailable</p>
          </div>
        </div>
        <div class="column is-5 mt-1 mb-1 overview-list">
          <div>
            Created: <span>{{ asset.created_at | date('L LTS') }}</span>
          </div>
          <div>
            Last {{ Naming.Inspection }}:
            <span v-if="overview.latestInspection" class="has-tooltip"
                  :data-tooltip="`${overview.latestInspection.created_at}`">
                {{ overview.latestInspection.created_at | date('L LTS') }}</span>
            <span v-else>Unavailable</span>
          </div>
          <div>
            Next {{ Naming.Inspection }}:
            <span v-if="asset.next_checklist_inspection">
                &nbsp;{{ asset.next_checklist_inspection.next_inspection | date('L') }}</span>
            <span v-else>Unavailable</span>
          </div>
          <div>
            {{ Naming.Asset }} Type: <span>{{ asset.type.name }}</span>
          </div>
          <div>
            {{ Naming.Site }}: <span>{{ asset.zone.site.name }}</span>
          </div>
          <div>
            {{ Naming.Zone }}: <span>{{ asset.zone.name }}</span>
          </div>
        </div>

      </div>
      <div class="is-flex justify-between columns mt-2" v-if="hasUpcomingInspections">
        <div class="column is-6 p-0 pr-1" v-if="shouldShowOnlyUpcomingInspections">
          <table class="table has-border-thin-grey">
            <caption class="is-uppercase title is-6">Upcoming {{ Naming.Inspections }}</caption>
            <thead>
            <tr>
              <th>{{ Naming.Checklist }}</th>
              <th class="is-narrow">Next {{ Naming.Inspection }} Date</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="upcomingInspection in onlyUpcomingInspections" :key="upcomingInspection.id">
              <td>
                <router-link
                    v-if="$root.hasAbility('manage-checklists', 'Billow\\Models\\Job') && upcomingInspection.checklist_id"
                    class="has-text-info"
                    :to="{ name: 'checklist-edit', params: { checklist: upcomingInspection.checklist_id }}">
                  <span>{{ upcomingInspection.checklist_name }}</span>
                </router-link>
              </td>
              <td class="is-narrow has-text-right">
                {{ upcomingInspection.next_inspection | date( 'L' ) }}
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <div class="column is-6 p-0 pl-1" v-if="shouldShowOnlyOverdueInspections">
          <table class="table has-border-thin-grey">
            <caption class="is-uppercase title is-6">Overdue {{ Naming.Inspections }}</caption>
            <thead>
            <tr>
              <th>{{ Naming.Checklist }}</th>
              <th class="is-narrow">Next {{ Naming.Inspection }} Date</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="overdueInspection in overdueInspections" :key="overdueInspection.id">
              <td class="has-text-info">
                <router-link
                    v-if="$root.hasAbility('manage-checklists', 'Billow\\Models\\Job') && overdueInspection.checklist_id"
                    class="has-text-info"
                    :to="{ name: 'checklist-edit', params: { checklist: overdueInspection.checklist_id }}">
                  <span>{{ overdueInspection.checklist_name }}</span>
                </router-link>
              </td>
              <td class="is-narrow has-text-right">
                {{ overdueInspection.next_inspection | date( 'L' ) }}
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="columns mt-2">
        <div class="column">
          <h4 class="is-uppercase has-text-centered title is-6 mb-1">Last Recorded Location</h4>
          <div v-if="asset.location && asset.location.latitude">
            <GmapMap
                :center="{ lat: asset.location.latitude, lng: asset.location.longitude }"
                :zoom="16"
                :options="googleMapOptions"
                map-type-id="terrain"
                style="width: 100%; height: 300px">
              <GmapMarker :position="{ lat: asset.location.latitude, lng: asset.location.longitude }"/>
            </GmapMap>
            <p class="has-text-centered has-text-weight-bold mt">
              {{ 'lat: ' + asset.location.latitude + ' - lng: ' + asset.location.longitude }}
            </p>
          </div>
          <div v-else class="has-background-cool-blue is-flex align-center justify-center"
               style="height: 100px; width: 100%;">
            <div class="has-text-centered">
              <icon class="is-large" icon="map-marker-alt 3x"/>
              <p>Location not enabled.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </loader>
</template>

<script>
import {mapGetters} from 'vuex'
import defaultMapStyles from '@/theme/gmaps/default'
import moment from "moment";

export default {

  data: () => ({
    loading: true,
    googleMapOptions: {
      styles: defaultMapStyles
    }
  }),

  created() {
    Promise.all([
      this.$store.dispatch('asset/loadAsset', this.$route.params.asset),
      this.$store.dispatch('asset/loadOverview', this.$route.params.asset),
      this.$store.dispatch('inspection/getAssetUpcomingInspections', this.$route.params.asset),
    ]).then(() => {
      this.loading = false
    })
  },

  computed: {
    ...mapGetters('asset', [
      'asset',
      'overview'
    ]),
    ...mapGetters('inspection', ['upcomingInspections']),
    assetCustomFields() {
      let customFields = this.asset.type.custom_fields_object
      let customFieldValues = this.asset.meta_data || []

      return _.map(customFields, field => {
        field.value = customFieldValues[field.field_id]

        return field
      })
    },
    overdueInspections() {
      return _.filter(this.upcomingInspections, upcomingInspection => moment(upcomingInspection.next_inspection).isBefore(moment()))
    },
    onlyUpcomingInspections() {
      return _.filter(this.upcomingInspections, upcomingInspection => moment(upcomingInspection.next_inspection).isSameOrAfter(moment()))
    },
    hasUpcomingInspections() {
      return this.upcomingInspections?.length > 0
    },
    shouldShowOnlyUpcomingInspections() {
      return this.onlyUpcomingInspections.length > 0
    },
    shouldShowOnlyOverdueInspections() {
      return this.overdueInspections.length > 0
    }
  }
}
</script>
