<template>
  <tr v-if="!editing">
    <td><p @click="editingField" class="hover-bold">{{ status.name }}</p></td>
    <td>{{ status.description || '-' }}</td>
    <td>{{ status.options.length }}</td>
    <td class="is-flex justify-end">
      <action-button :disabled="!canEdit || editing === true"
                     @click="editingField"
                     class="is-rounded is-borderless hover-background-grey">
        <icon icon="chevron-down" class="has-text-grey"/>
      </action-button>
    </td>
  </tr>

  <tr v-else class="has-background-white-bis">
    <td>
      <text-input :disabled="!canEdit"
                  required
                  @input="fieldEdited"
                  v-model="status.name"
                  placeholder="Enter status name">
        Status Name
      </text-input>
    </td>
    <td>
      <text-input :disabled="!canEdit"
                  @input="fieldEdited"
                  v-model="status.description"
                  placeholder="Enter status description">
        Status Description
      </text-input>
    </td>
    <!--    Options-->
    <td v-if="canEdit" >
      <field-label class="has-text-weight-bold" required>Options</field-label>
      <dynamic-option v-for="(option, index) in status.options"
                      :option="option"
                      :index="index"
                      :key="index"
                      @addOption="addOption"
                      @removeOption="removeOption"
                      @fieldEdited="fieldEdited"
      />
      <div class="is-flex align-items-center justify-center">
        <action-button :disabled="!canEdit"
                       @click="addOption"
                       data-tooltip="Add Option"
                       class="is-rounded has-tooltip-bottom hover-success">
          <icon icon="plus" class="has-text-grey"/>
        </action-button>
      </div>
    </td>
    <td v-else>
      <p v-for="(option, index) in status.options" :key="index">- {{ option.value }}</p>
    </td>
    <!--    Buttons-->
    <td>
      <div class="is-flex justify-end align-items-start p-05">
        <icon v-if="(!isNew) || (isNew && !edited)" @click="cancelEdits" icon="chevron-up" data-tooltip="Hide"
              class="has-text-grey has-tooltip-bottom"/>
      </div>
      <div class="is-flex justify-center align-items-center" style="margin-top:8px">
        <action-button :working="working"
                       :disabled="!canSave || !canEdit"
                       left-icon="save"
                       class="is-rounded mr-1 has-tooltip-bottom is-success"
                       data-tooltip="Save"
                       @click="save">
          Save
        </action-button>
        <action-button :working="working"
                       v-if="status.id"
                       :disabled="canEdit === false || canDelete === false"
                       left-icon="trash"
                       class="is-rounded has-tooltip-left is-danger"
                       :data-tooltip="deleteButtonTooltip"
                       @click="deleteStatus">
          Delete
        </action-button>
        <action-button v-else
                       left-icon="times"
                       class="is-rounded is-warning"
                       @click="cancelEdits">
          Cancel
        </action-button>
      </div>
    </td>
  </tr>
</template>

<script>

import DynamicOption from "@/views/asset-type/partials/DynamicOption.vue";
import DynamicStatusOption from "@/models/DynamicStatusOption";
import DynamicStatus from "@/models/DynamicStatus";

export default {
  components: {DynamicOption},
  props: {
    status: {
      type: Object,
      default: () => ({
        id: null,
        name: '',
        description: '',
        asset_type_id: null,
        options: [
          {
            value: '',
            dynamic_status_id: null
          }
        ],
        editable: true
      }),
    },
    locked: {
      type: Boolean
    }
  },
  data: () => ({
    working: false,
    edited: false,
    editing: false,
  }),

  created() {
    this.$on('adding-status', this.setToEditing);
  },

  methods: {
    save() {
      if (this.optionValuesAreUnique === false) {
        return this.$toast.warning("All 'Options' values are not unique. Please use unique values for each option")
      }
      if (this.status.id) {
        this.updateStatus()
      } else {
        this.saveNewStatus()
      }
    },
    async saveNewStatus() {
      this.working = true
      this.status.asset_type_id = this.$route.params.assetType
      await this.$store.dispatch('dynamicStatus/storeDynamicStatus', this.status).then(() => {
        this.status = new DynamicStatus
        this.$emit('reload')
        this.$toast.success("Dynamic Status Created")
        this.working = false
      }).catch((error) => {
        if (error.response && error.response.status === 422) {
          this.$toast.warning(error.response.data.message)
        } else {
          this.$whoops()
        }
        this.working = false
      })
    },
    async updateStatus() {
      this.working = true
      await this.$store.dispatch('dynamicStatus/updateDynamicStatus', this.status).then(() => {
        this.editingField()
        this.$toast.success("Dynamic Status Updated")
        this.edited = false
        this.working = false
      }).catch((error) => {
        if (error.response && error.response.status === 422) {
          this.$toast.warning(error.response.data.message)
        } else {
          this.$whoops()
          this.$emit('reload')
        }
        this.working = false
      })
    },
    async deleteStatus() {
      if (await this.$confirm({
        title: "Delete Dynamic Status",
        message: "Are you sure you want to delete this dynamic status? Deleting this dynamic status and options cannot be undone.",
        confirmText: "Delete",
        confirmClass: 'is-danger'
      })) {
        this.working = true
        await this.$store.dispatch('dynamicStatus/deleteDynamicStatus', this.status.id).then(() => {
          this.$emit('reload')
          this.$toast.success("Dynamic Status Deleted")
          this.working = false
        }).catch((error) => {
          this.working = false
          this.$whoops()
        })
      }
    },
    async deleteOption(optionId) {
      if (this.status.options.length < 2) return this.$toast.warning('The status must have at least one option')
      this.working = true
      await this.$store.dispatch('dynamicStatus/deleteDynamicStatusOption', optionId).then(() => {
        this.$toast.success("Dynamic Status Deleted")
        this.working = false
      }).catch((error) => {
        this.working = false
        this.$whoops()
      })
    },
    editingField() {
      this.editing = true
      this.$emit('editingField', this.status.id)
    },
    fieldEdited() {
      this.edited = true
    },
    addOption() {
      this.fieldEdited()
      this.status.options.push(new DynamicStatusOption)
    },
    removeOption(index, option) {
      this.edited = true
      if (this.status.options.length > 1) {
        this.status.options.splice(index, 1)
      }
      if (option.id) {
        return this.deleteOption(option.id)
      }
      option.value = ''
    },
    cancelEdits() {
      this.resetIfNew()
      this.$emit('cancel')
      this.editing = false
    },
    resetIfNew() {
      if (this.isNew) {
        this.status = new DynamicStatus
      }
    },
    setToEditing() {
      this.editing = true
    },

  },

  computed: {
    canSave() {
      return this.status.name.length > 0 &&
          this.status.options?.length > 0 &&
          this.status.options.every(option => option.value !== '')
    },
    canDelete() {
      let hasId = this.status.id !== null;
      let hasNoOptionsWithAssets = this.status.options.filter(option => option.assets_count > 0).length < 1

      return hasId && hasNoOptionsWithAssets
    },
    deleteButtonTooltip() {
      let assetName = this.Naming.Assets
      return this.canDelete ? 'Delete status' : `Un-assign the status options from all ${assetName} to delete`
    },
    canEdit() {
      return this.status.editable && this.locked === false
    },
    isNew() {
      return this.status.id === null
    },
    optionValuesAreUnique() {
      let values = this.status.options.map(option => option.value)
      return new Set(values).size === values.length
    }

  },
};
</script>
