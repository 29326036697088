<template>
  <div class="option-text-box tooltip">
  <text-input @input="fieldEdited"
              @keydown.enter="addOption"
              v-model="option.value"
              placeholder="Enter option value">
    <template #right>
      <div class="buttons has-addons">
        <action-button :disabled="!canDeleteOption"
                       :class="{'has-tooltip-grey' : !canDeleteOption}"
                       @click="removeOption"
                       :data-tooltip="!canDeleteOption ? 'This option has been assigned. Remove all assignments to delete.' : false"
                       class="hover-danger">
          <icon icon="times" class="has-text-grey"/>
        </action-button>
      </div>
    </template>
  </text-input>
  </div>
</template>

<script>

export default {
  props: {
    option: {
      type: Object,
      default: () => ({
        id: null,
        value: '',
      }),
    },
    index: {
      type: Number,
      default: 0
    }
  },
  data: () => ({
  }),

  created() {
  },

  methods: {
    removeOption(){
      this.$emit('removeOption', this.index, this.option)
    },
    fieldEdited(){
      this.$emit('fieldEdited')
    },
    addOption(){
      this.$emit('addOption')
    }

  },

  computed: {
    canDeleteOption(){
      return !this.option.assets_count > 0
    }
  },
};
</script>
