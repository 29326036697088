<template>
<page icon="coins" :title="Naming.AssetTypes" v-bind="{ loading }" :loading-text="`Loading ${Naming.AssetTypes}…`">
  <div slot="tools">
    <router-link exact class="button is-rounded is-primary" :to="{ name: 'create-asset-type' }">
      <icon icon="plus"/>
      <span>{{ Naming.AssetType }}</span>
    </router-link>
  </div>

  <div class="box is-paddingless">

    <form class="p-1">
      <text-input
        v-model="filters.search"
        :placeholder="`Search ${Naming.AssetTypes}`"
        classes="has-addons is-rounded">
        <template #right>
          <submit-button @submit="search" class="is-rounded" left-icon="search">
            Search
          </submit-button>
        </template>
      </text-input>
    </form>
    <pager class="pl" :pageable="assetTypes" @nav="goToPage" :context="Naming.AssetType.toLowerCase()"/>
    <table class="table is-fullwidth">
      <thead class="is-sticky">
        <tr>
          <th>Name</th>
          <th class="has-text-centered">Risk</th>
          <th class="has-text-right is-narrow">{{ Naming.Checklists }}</th>
          <th class="has-text-right">{{ Naming.Assets }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="assetType in assetTypes.data" :key="assetType.id">
          <td>
            <router-link class="has-text-link" :to="{
                name: 'asset-type-overview',
                params: {
                  assetType: assetType.id
                }
              }">
              {{ assetType.name }}
              <icon icon="lock" class="has-text-danger" v-if="assetType.locked"/>
            </router-link>
          </td>
          <td class="has-text-centered">
            <span v-if="assetType.risk">{{ assetType.risk.level }}</span>
          </td>
          <td class="has-text-right">
            <span>{{ assetType.checklists_count }}</span>
          </td>
          <td class="has-text-right">{{ assetType.assets_count || 0 }}</td>
        </tr>
      </tbody>
    </table>
    <p class="has-text-centered p-2" v-if="!assetTypes.data.length">
      No {{ Naming.AssetTypes.toLowerCase() }} found
    </p>
    <pager class="pl" :pageable="assetTypes" @nav="goToPage" :context="Naming.AssetType.toLowerCase()"/>
  </div>
</page>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import { newAssetType, editAssetType } from '@/modals'

export default {

  data: () => ({
    loading: true,
    filters: {
      search: ''
    }
  }),

  async created() {
    await this.loadAssetTypes()
    this.loading = false
  },

  methods: {
    loadAssetTypes() {
      this.$scrollToTop()
      this.$store.dispatch('assetType/loadAssetTypes', {
        path: 'api' + this.$route.fullPath,
        params: this.filters
      })
    },
    goToPage(page) {
      this.$scrollToTop()
      this.$router.push(page)
    },
    search() {
      this.$scrollToTop()
      this.$router.push({
        name: 'asset-type-index',
        query: this.filters
      })
    },
    createType() {
      newAssetType()
    },
  },

  computed: {
    ...mapGetters('assetType', [
      'assetTypes'
    ])
  },

  watch: {
    '$route': 'loadAssetTypes'
  }

}
</script>
