<template>
<page icon="coins" :title="`New ${Naming.AssetType}`" v-bind="{ loading }">
  <div class="box">
    <div class="columns">
      <div class="column">
        <text-input
          classes="is-medium"
          required
          placeholder="ex: Vehicle"
          :description="`A descriptive name for this ${Naming.AssetType.toLowerCase()}`"
          :error="$root.errors.name"
          :value="assetType.name"
          @input="name">
          Name
        </text-input>
      </div>
      <div class="column">
        <data-selector
          classes="is-medium"
          required
          :description="`Indicate the level of risk that ${Naming.Assets.toLowerCase()} of this type will carry.`"
          :error="$root.errors.risk_id"
          :items="riskLevelList"
          value-key="id"
          label-key="level"
          :value="assetType.risk_id"
          @input="risk">
          Risk Level
        </data-selector>
      </div>
    </div>

    <div class="columns">
      <div class="column">
        <h4 class="is-size-4 has-text-weight-light">{{ Naming.Checklists }}</h4>
      </div>
    </div>

    <div class="columns">
      <div class="column">
        <select-checklists
          :checklists="checklistList"
          :value="assetType.checklists"
          @input="setChecklists"/>
        <field-error :error="$root.errors.checklists"/>
      </div>
    </div>

    <div class="columns">
      <div class="column">
        <notification class="is-cool-blue">
          <checkbox-input
            :value="assetType.enforce_checklist_order"
            @input="enforceChecklistOrder"
            classes="is-circle is-primary"
            :label="false"
            :inner-label="`Enforce ${Naming.Checklists.toLowerCase()} to be used in the order shown above. This will prevent ${Naming.Technicians.toLowerCase()} from selecting a ${Naming.Checklist.toLowerCase()} when scanning an ${Naming.Asset.toLowerCase()} of this type`"/>
        </notification>
      </div>
    </div>

    <div class="columns" v-if="barcodelessInspectionsAreEnabled">
      <div class="column">
        <notification class="is-cool-blue">
          <checkbox-input
              :value="assetType.allow_barcodeless_inspections"
              @input="allowBarcodelessInspections"
              classes="is-circle is-primary"
              :label="false"
              :inner-label="`Allow ${Naming.Inspections.toLowerCase()} to be performed without scanning a barcode.`"/>
        </notification>
      </div>
    </div>
  </div>
  <div class="box">
    <div class="columns">
      <div class="column">
        <h4 class="is-size-4 has-text-weight-light">{{ Naming.CustomFields }}</h4>
        <p class="has-text-cool-blue-text">When onboarding {{ Naming.Assets.toLowerCase() }} of this type, the fields you add below will be shown to the {{ Naming.Technician.toLowerCase() }}, allowing you to store additional information related to the {{ Naming.Asset.toLowerCase() }}, such as a registration number.</p>
        <message class="is-warning mt">{{ Naming.CustomFields }} are considered fixed data points. These values will typically not change over time and are best suited for things like serial numbers etc. For data points that fluctuate or change, consider creating a {{ Naming.Check.toLowerCase() }} on a {{ Naming.Checklist.toLowerCase() }} instead.</message>
      </div>
    </div>

    <div>
      <new-custom-field />
      <custom-field
        v-for="(field, index) in assetType.custom_fields"
        :field="field"
        :key="index"/>
      <p v-if="hasDuplicateLabel" class="has-text-danger mt-2">Two of your {{ Naming.CustomFields }} have the same label. The field label should be unique for this {{ Naming.AssetType }}.</p>
      <p v-if="hasAnEmptyLabel" class="has-text-danger mt-2">One of your {{ Naming.CustomFields }} has an empty label.</p>
      <p v-if="hasDuplicateFieldId" class="has-text-danger mt-2">Two of your {{ Naming.CustomFields }} have the same ID. Please remove the new field and re-add it.</p>
    </div>

    <div class="columns">
      <div class="column">
        <h4 class="is-size-4 has-text-weight-light mt-1">{{ Naming.AssetGroups }}</h4>
        <data-selector
          multiple
          :items="assetGroupList"
          value-key="id"
          label-key="description"
          :value="assetType.zone_types"
          @input="updateAssetGroups"/>
      </div>
    </div>
  </div>

<!--    Dynamic Statuses-->
    <div class="box p-2" v-if="$root.hasAbility('manage-dynamic-statuses', 'App\\Models\\DynamicStatus')" >
      <div v-if="addingDynamicStatuses === false" >
        <h4 class="is-size-4 has-text-weight-light">Dynamic Statuses</h4>
        <p class="has-text-cool-blue-text">These are secondary statuses that can be applied to assets of this type.</p>
        <action-button @click="addingDynamicStatuses = true" class="mt-1" left-icon="plus" >Add Dynamic Statuses</action-button>
      </div>

      <div v-else >
        <div class="is-flex justify-between mb-1">
          <h4 class="is-size-4 has-text-weight-light">Dynamic Statuses</h4>
          <action-button v-if="!hasStatuses" @click="addingDynamicStatuses = false" class="mt-1" left-icon="times" >Close</action-button>
        </div>
        <message v-if="!statusNamesAreUnique" class="is-warning" >Dynamic Status names must be unique for the {{Naming.AssetType}}. Please rename the duplicate status name.</message>
        <table class="table is-fullwidth is-hoverable" v-if="hasStatuses" >
          <thead>
          <tr>
            <th>Name</th>
            <th>Description</th>
            <th>Options</th>
            <th class="is-narrow">Edit Status</th>
          </tr>
          </thead>
          <tbody>
          <new-dynamic-status v-for="(dynamicStatus, index) in assetType.dynamicStatuses" :key="index" :status="dynamicStatus" @delete="removeStatus" />
          </tbody>
        </table>
        <hr class="mt-2 mb-2" v-if="hasStatuses">
        <add-dynamic-status @addStatus="addNewStatus"/>
      </div>
    </div>

    <div class="columns">
      <div class="column">
        <submit-button :disabled="!canSave" :working="working" @submit="store" class="is-success is-medium mt-1" left-icon="check">Create {{ Naming.AssetType }}</submit-button>
      </div>
    </div>

</page>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import CustomField from './partials/CustomField'
import SelectChecklists from './partials/SelectChecklists'
import NewCustomField from './partials/NewCustomField'
import AddDynamicStatus from "@/views/asset-type/partials/AddDynamicStatus.vue";
import NewDynamicStatus from './partials/NewDynamicStatus.vue';
import arrayHelper from "@/utils/arrayHelper";

export default {

  components: {
    NewDynamicStatus,
    AddDynamicStatus,
    SelectChecklists,
    CustomField,
    NewCustomField
  },

  data: () => ({
    loading: true,
    working: false,
    addingNewField: false,
    addingDynamicStatuses: false,
  }),

  beforeCreate() {
    this.$store.commit('assetType/clearType')
  },

  created() {
    Promise.all([
      this.$store.dispatch('checklist/loadChecklistList'),
      this.$store.dispatch('riskLevel/loadRiskLevelList'),
      this.$store.dispatch('assetGroup/loadAssetGroupList'),
    ]).then(() => this.loading = false)
  },

  destroyed() {
    this.clearType()
  },

  methods: {
    ...mapMutations('assetType', [
      'name',
      'setChecklists',
      'code',
      'clearType',
      'risk',
      'enforceChecklistOrder',
      'allowBarcodelessInspections',
      'updateAssetGroups',
      'addStatus',
      'removeStatus'
    ]),
    store() {
      this.working = true
      this.$store.dispatch('assetType/store').then(() => {
        this.working = false
        this.$router.push({
          name: 'asset-type-index'
        })
        this.$toast.success(this.Convert(this.$lang.assetType.created))
      }).catch(error => {
        this.working = false
        this.$whoops()
      })
    },
    addNewStatus(status){
      this.addStatus(status)
    },
    removeStatus(index){
      this.removeStatus(index)
    },
    optionValuesAreUnique(status){
      let values = status.options.map(option => option.value)
      return new Set(values).size === values.length
    },
    allOptionsHaveValues(status){
      return status.name.length > 0 &&
          status.options?.length > 0 &&
          status.options.every(option => option.value !== '')
    }
  },

  computed: {
    ...mapGetters('assetType', [
      'assetType'
    ]),
    ...mapGetters('checklist', [
      'checklistList'
    ]),
    ...mapGetters('riskLevel', [
      'riskLevelList'
    ]),
    ...mapGetters('assetGroup', [
      'assetGroupList'
    ]),
    ...mapGetters('company', [
      'company'
    ]),
    ...mapGetters('auth', [
      'authenticated'
    ]),
    barcodelessInspectionsAreEnabled() {
      return this.isCompanyPrimaryUser && this.company.allow_barcodeless_inspections === true
    },
    hasStatuses(){
      return this.assetType.dynamicStatuses.length > 0
    },
    statusNamesAreUnique(){
      if(!this.hasStatuses) return true
      let values = this.assetType.dynamicStatuses.map(status => status.name)
      return new Set(values).size === values.length
    },
    canSave(){
      return this.statusNamesAreUnique &&
          this.assetType.dynamicStatuses.every(status => {
            return this.optionValuesAreUnique(status) &&
                this.allOptionsHaveValues(status)
          })
      && !(this.hasAnEmptyLabel || this.hasDuplicateLabel || this.hasDuplicateFieldId)
    },
    hasAnEmptyLabel() {
      return this.assetType.custom_fields?.some(customField => !customField.label || customField.label.trim().length < 1)
    },
    hasDuplicateLabel() {
        return arrayHelper.findDuplicates(this.assetType.custom_fields, 'label').length > 0
    },
    hasDuplicateFieldId() {
        return arrayHelper.findDuplicates(this.assetType.custom_fields, 'field_id').length > 0
    },
  }
}
</script>
