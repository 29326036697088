<template>
  <loader v-bind="{ loading }">
    <div class="box">
      <message class="is-warning mt">{{ Naming.CustomFields }} are considered fixed data points. These values will typically not change over time and are best suited for things like serial numbers etc. For data points that fluctuate or change, consider creating a {{Naming.Check.toLowerCase()}} on a  {{ Naming.Checklist.toLowerCase() }} instead.</message>
      <new-custom-field v-if="!assetType.locked" />
      <custom-field
        v-for="(field, index) in assetType.custom_fields"
        :field="field"
        :locked="assetType.locked"
        :key="index"/>
      <p v-if="hasDuplicateLabel" class="has-text-danger mt-2">Two of your {{ Naming.CustomFields }} have the same label. The field label should be unique for this {{ Naming.AssetType }}.</p>
      <p v-if="hasAnEmptyLabel" class="has-text-danger mt-2">One of your {{ Naming.CustomFields }} has an empty label.</p>
      <p v-if="hasDuplicateFieldId" class="has-text-danger mt-2">Two of your {{ Naming.CustomFields }} have the same ID. Please remove the new field and re-add it.</p>
    </div>

    <action-button 
      :working="working" 
      :disabled="!canSave || assetType.locked"
      left-icon="check" 
      class="is-success is-rounded is-medium"
      @click="save">
      Save
    </action-button>
  </loader>
</template>

<script>
import { mapGetters } from 'vuex'
import CustomField from './partials/CustomField'
import NewCustomField from './partials/NewCustomField'
import arrayHelper from '@/utils/arrayHelper.js'

export default {

  components: {
    CustomField,
    NewCustomField
  },

  data: () => ({
    loading: true,
    working: false,
    saved: false,
  }),

  created() {
    Promise.all([
      this.$store.dispatch('assetType/loadAssetType', this.$route.params.assetType)
    ]).then(() => this.loading = false)
  },

  methods: {
    save() {
      this.working = true
      this.$store.dispatch('assetType/save').then(() => {
        this.working = false
        this.saved = true
        this.$toast.success(this.Convert(this.$lang.assetType.saved))
      }).catch(() => {
        this.working = false
        this.saved = false
        this.$whoops()
      })
    },
    async confirmLeave() {
      return this.$confirm({
        title: "Warning",
        message: `Leave without saving?`,
        confirmText: "Leave Page",
        cancelText: "Continue",
        confirmClass: 'is-danger',
        cancelClass: 'is-info',
      })
          .catch(() => {
            this.$whoops()
          })
    },
  },

  computed: {
    ...mapGetters('assetType', [
      'assetType'
    ]),
    canSave() {
      return !(this.hasAnEmptyLabel || this.hasDuplicateLabel || this.hasDuplicateFieldId);
    },
    hasAnEmptyLabel() {
      return this.assetType.custom_fields?.some(customField => !customField.label || customField.label.trim().length < 1)
    },
    hasDuplicateLabel() {
      return arrayHelper.findDuplicates(this.assetType.custom_fields, 'label').length > 0
    },
    hasDuplicateFieldId() {
      return arrayHelper.findDuplicates(this.assetType.custom_fields, 'field_id').length > 0
    },
    async beforeRouteLeave(to, from, next) {
      if (!this.saved) {
        if (await this.confirmLeave()) {
          next()
        }
      } else {
        next()
      }
    }
  }
}
</script>