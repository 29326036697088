<template>
<page icon="bezier-curve" :title="Naming.AssetGroups" v-bind="{ loading }" :loading-text="`Loading ${Naming.AssetGroups}…`">
  <div slot="tools">
    <action-button class="is-primary is-rounded" @click="createGroup" left-icon="plus">{{ Naming.AssetGroup }}</action-button>
  </div>

  <div class="box is-paddingless">

    <form class="p-1">
      <text-input
        v-model="filters.search"
        :placeholder="`Search ${Naming.AssetGroups}`"
        classes="has-addons is-rounded">
        <template #right>
          <submit-button @submit="search" class="is-rounded" left-icon="search">
            Search
          </submit-button>
        </template>
      </text-input>
    </form>

    <pager class="pl" :pageable="assetGroups" @nav="goToPage" :context="Naming.AssetGroup.toLowerCase()"/>

    <table class="table is-fullwidth">
      <thead>
        <tr>
          <th>Description</th>
          <th>{{ Naming.Zones }}</th>
          <th>Risk Level</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="assetGroup in assetGroups.data" :key="assetGroup.id">
          <td>
            <a @click="editType(assetGroup)">{{ assetGroup.description }}</a>
          </td>
          <td>
            {{ assetGroup.zones_count || 0 }}
          </td>
          <td>
            <span v-if="assetGroup.risk">
              {{ assetGroup.risk.level }}
            </span>
          </td>
        </tr>
      </tbody>
    </table>
    <p class="has-text-centered" v-if="!assetGroups.data.length">
      No {{ Naming.AssetGroups }} found
    </p>
    <pager class="pl" :pageable="assetGroups" @nav="goToPage" :context="Naming.AssetGroup.toLowerCase()"/>
  </div>
</page>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import { createAssetGroup, editAssetGroup } from '@/modals'

export default {

  data: () => ({
    loading: true,
    filters: {
      search: ''
    }
  }),

  created() {
    this.loadAssetGroups()
  },

  methods: {
    goToPage(path) {
      this.$router.push(path)
      this.$scrollToTop()
    },
    async loadAssetGroups() {
      await this.$store.dispatch('assetGroup/loadAssetGroups', this.$route.fullPath)
      this.loading = false
    },
    loadAssetGroupsByPath(path) {
      this.$store.dispatch('assetGroup/loadAssetGroups', path)
      this.loading = false
    },
    createGroup() {
      createAssetGroup()
    },
    editType(assetGroup) {
      editAssetGroup(assetGroup).then(reload => {
        if(reload) this.loadAssetGroupsByPath('/asset-groups')
      })
    },
    search() {
      this.$scrollToTop()
      this.$router.push({
        name: 'asset-group-index',
        query: this.filters
      })
    },
  },

  computed: {
    ...mapGetters('assetGroup', [
      'assetGroups'
    ])
  },

  watch: {
    '$route': 'loadAssetGroups'
  },

}
</script>
