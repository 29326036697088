<template>
  <div>
    <hr>
    <div class="columns">
      <div class="column">
        <text-input
            :value="field.label"
            :disabled="locked"
            @input="updateFieldLabel"
            placeholder="eg. VIN Number"
            hint="The label to show at the top of the field.">
          Field Label
        </text-input>
        <p class="help is-danger" v-if="!field.label.length">A Field Label is required.</p>
      </div>
      <div class="column">
        <text-input
            :value="field.placeholder"
            :disabled="locked"
            @input="updateFieldPlaceholder"
            hint="What should show inside the field when empty?">
          Placeholder
        </text-input>
      </div>
      <div class="column">
        <text-input
            :value="field.default"
            :disabled="field.options?.length > 0 || locked"
            @input="updateFieldDefault"
            hint="The default value.">
          Default Value
        </text-input>
      </div>
      <div class="column is-narrow">
        <checkbox-input
            classes="is-circle is-primary is-medium"
            :inner-label="field.is_required ? 'Yes' : 'No'"
            hint="Is the field required when onboarding?"
            :value="field.is_required"
            :disabled="locked"
            @input="toggleRequiredField">
          Required?
        </checkbox-input>
      </div>
      <div class="column is-flex align-end is-narrow" v-if="!locked" >
        <action-button
            @click="showPredefinedValuesEditor"
            :disabled="locked"
            class="is-rounded is-warning">
          <icon icon="plus" class="m-auto"/>Predefined Values ({{ predefinedValueCount }})
        </action-button>
      </div>
      <div v-else class="column">
        <p class="has-text-weight-bold">Predefined Values</p>
        <p class="ml-1" v-for="option in field.options">
          - {{option.value}}
        </p>
      </div>
      <div class="column is-flex align-end is-narrow">
        <action-button
            @click="deleteField"
            :disabled="locked"
            class="is-rounded is-danger">
          <icon icon="trash"/>
        </action-button>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import {predefinedValuesEditor} from "@/modals";

export default {
  props: {
    field: {
      type: Object,
      default: () => ({
        field_id: '',
        label: '',
        is_required: false,
        options: [],
        default: false,
      }),
    },
    locked: {
      type: Boolean,
    }
  },

  methods: {
    updateFieldLabel(value) {
      this.$store.commit('assetType/updateFieldLabel', {
        fieldId: this.field.field_id,
        value
      })
    },
    updateFieldPlaceholder(value) {
      this.$store.commit('assetType/updateFieldPlaceholder', {
        fieldId: this.field.field_id,
        value
      })
    },
    updateFieldDefault(value) {
      this.$store.commit('assetType/updateFieldDefault', {
        fieldId: this.field.field_id,
        value
      })
    },
    toggleRequiredField() {
      this.$store.commit('assetType/toggleRequiredField', this.field.field_id)
    },
    async deleteField() {
      if (await this.$confirm({
        title: this.Convert('Delete Field'),
        message: this.Convert('Are you sure you want to remove this custom field?')
      })) {
        this.$store.commit('assetType/deleteField', this.field)
      }
    },
    showPredefinedValuesEditor() {
      //  set the field id
      this.$store.commit('assetType/setFieldId', this.field.field_id)
      //  show the modal
      predefinedValuesEditor().then(
          // send the request
      )
    },
  },

  computed: {
    ...mapGetters('assetType', [
      'assetType'
    ]),
    predefinedValueCount() {
      return this.field?.options?.length ?? 0
    },
  },
};
</script>
