<template>
  <article class="sortable-selector">
    <!-- Add checklists -->
    <data-selector
      ref="checklistSelector"
      required
      searchable
      classes="is-medium"
      :prompt-label="`Choose a ${Naming.Checklist.toLowerCase()}`"
      :empty-text="selectedChecklists.length ? `You’ve selected all available ${Naming.Checklists.toLowerCase()}` : `There are no available ${Naming.Checklists.toLowerCase()} to select.`"
      :items="filteredChecklists"
      value-key="id"
      label-key="name"
      :value="checklist"
      @input="addChecklist">
      Add {{ Naming.Checklist }}(s)
      <template #item="{ item }">
        <div>
          <p class="has-text-weight-bold">{{ item.name }}</p>
          <p class="has-text-weight-medium is-size-7 has-text-grey">Frequency: {{ item.frequency }} {{ 'day' | plural(item.frequency) }} • Pass Percentage: {{ item.pass_percentage }}%</p>
        </div>
      </template>
    </data-selector>

    <!-- No checklists -->
    <notification class="is-cool-blue has-text-centered p-2 is-marginless" v-if="!selectedChecklists.length">
      <p class="has-text-weight-bold">No {{ Naming.Checklists }} Selected</p>
      <p>You haven’t selected any {{ Naming.Checklists.toLowerCase() }} yet.</p>
    </notification>

    <!-- Sort and remove checklists -->
    <draggable
      handle="#handle"
      :animation="200"
      v-model="selectedChecklists"
      group="checklists">
      <transition-group>
        <div class="selected-item"
          v-for="(checklist, index) in selectedChecklists"
          :key="checklist.id">
          <icon icon="grip-vertical" id="handle"/>
          <div class="grid has-5-columns gap-1 flex-grow">
            <p class="has-text-weight-medium is-2-columns">{{ checklist.name }}</p>
            <p class="has-text-cool-blue-text">{{ checklist.checks.length }} {{ Naming.Checks.toLowerCase() | plural(checklist.checks.length) }}</p>
            <p class="has-text-cool-blue-text">
              <span v-if="checklist.frequency > 0">Every {{ checklist.frequency }} {{ 'day' | plural(checklist.frequency) }}</span>
              <span v-else>Does not repeat</span>
            </p>
            <p class="has-text-cool-blue-text">{{ checklist.pass_percentage }}% to pass</p>
          </div>
          <a class="delete" @click="removeChecklist(index)"></a>
        </div>
      </transition-group>
    </draggable>
  </article>
</template>

<script>
import differenceBy from 'lodash/differenceBy'

export default {

  props: {
    checklists: {
      type: Array,
      required: true
    },
    value: {
      type: Array,
      default: () => ([])
    }
  },

  data: () => ({
    checklist: null,
    selectedChecklists: [],
  }),

  computed: {
    filteredChecklists() {
      return differenceBy(this.checklists, this.selectedChecklists, 'id')
    }
  },

  created() {
    this.selectedChecklists = this.value
  },

  methods: {
    addChecklist(id) {
      if (!id) return
      this.selectedChecklists.push(this.checklists.find(checklist => checklist.id === id))
      this.$refs.checklistSelector.search = ''
      this.$refs.checklistSelector.selected = ''
    },

    removeChecklist(index) {
      this.selectedChecklists.splice(index, 1)
    }
  },

  watch: {
    selectedChecklists() {
      this.$emit('input', this.selectedChecklists)
    }
  },

}
</script>
