<template>
  <page icon="coins" :title="`${Naming.Asset} Manager`" v-bind="{ loading }">
    <template #tools>
      <div class="tabs is-toggle">
        <ul>
          <router-link exact tag="li" :to="{ name: 'asset-overview', params: routeParams}">
            <a>Overview</a>
          </router-link>
          <router-link exact tag="li" :to="{ name: 'asset-inspections', params: routeParams}"
            v-if="hasAbility('view-inspection-history', 'Billow\\Models\\Asset') || hasRole('client')">
            <a>{{ Naming.Inspection }} History</a>
          </router-link>
          <router-link v-if="hasAbility('view-inspection-history', 'Billow\\Models\\Asset') || hasRole('client')" exact tag="li" :to="{ name: 'history', params: routeParams}">
            <a>{{ Naming.Asset }} History</a>
          </router-link>
          <router-link tag="li" :to="{ name: 'asset-sensors', params: routeParams}">
            <a>Sensors</a>
          </router-link>
          <router-link v-if="canAccessTools()" exact tag="li" :to="{ name: 'asset-tools', params: routeParams}">
            <a>Tools</a>
          </router-link>
          <router-link v-if="hasAbility('manage-sub-assets', 'Billow\\Models\\Asset')" exact tag="li" :to="{ name: 'asset-components', params: routeParams}">
            <a>Components</a>
          </router-link>
          <router-link
            v-if="hasAbility('manage-documentation', 'Billow\\Models\\Asset')"
          exact tag="li" :to="{ name: 'asset-documents', params: routeParams }">
            <a>Documentation</a>
          </router-link>
          <router-link v-if="isAdmin && asset.type !== null && hasAbility('edit-assets', 'Billow\\Models\\Asset')" exact tag="li"
            :to="{ name: 'asset-dynamic-statuses', params: routeParams}">
            <a>Dynamic Statuses</a>
          </router-link>
          <router-link v-if="isAdmin && hasAbility('edit-assets', 'Billow\\Models\\Asset')" exact tag="li"
                       :to="{ name: 'asset-edit', params: routeParams}">
            <a>Edit</a>
          </router-link>
        </ul>
      </div>
    </template>
    <router-view/>
  </page>
</template>

<script>
import { mapGetters } from 'vuex'

export default {

  data() {
    return {
      loading: true
    }
  },

  created() {
    this.$store.dispatch('asset/loadAsset', this.$route.params.asset).then((asset) => {
      if(asset.zone) {
        this.$store.dispatch('site/loadSite', asset.zone.site_id).then(() => {
          this.loading = false
        })
      } else {
        this.loading = false
      }

    })
  },

  methods: {
    hasRole(name) {
      return this.user.roles.filter(role => role.name === name).length > 0
    },
    hasAbility(name, entityType) {
      return this.user.abilities?.filter(ability => ability.entity_type === entityType && ability.name === name).length > 0
    },
    canAccessTools(){
      return this.hasAbility('transfer-assets-same-client', 'Billow\\Models\\Asset')
        || this.hasAbility('transfer-assets-between-clients', 'Billow\\Models\\Asset')
        || this.hasAbility('decommission-assets', 'Billow\\Models\\Asset')
        || this.hasAbility('manage-sub-assets', 'Billow\\Models\\Asset');
    }
  },

  computed: {
    ...mapGetters('asset', [
      'asset'
    ]),
    ...mapGetters('site', [
      'site'
    ]),
    ...mapGetters('auth', [
      'user',
      'isAdmin'
    ]),
    routeParams() {
      return {
        asset: this.$route.params.asset
      }
    },
    isSubAsset() {
      return this.asset.asset_id !== null
    }
  },
}
</script>
