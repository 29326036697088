<template>
  <page icon="coins" :title="`All ${Naming.Assets}`" v-bind="{ loading }">

    <template #tools>
      <dropdown-wrapper position="bottom-end">
        <action-button
          slot="trigger"
          :working="exporting"
          class="is-primary is-rounded">
          Export
        </action-button>
        <dropdown-item @click="exportToFile('csv')">Export {{ Naming.Assets }} To CSV</dropdown-item>
        <dropdown-item @click="exportToFile('xlsx')">Export {{ Naming.Assets }} To Excel</dropdown-item>
        <dropdown-item @click="exportExcelReportWithChecks()">Export {{ Naming.Assets }} With {{ Naming.Checks }} To Excel (Maximum 1000)</dropdown-item>
      </dropdown-wrapper>
    </template>

    <div class="box">
      <asset-filters :hidden="['last_inspection']" :isLoadingData="loadingAssets"/>
    </div>

    <div class="box mt-1">
      <pager :pageable="assets" @nav="goToPage"/>
      <table class="table is-fullwidth is-hoverable">
        <thead>
          <tr>
            <th>{{ Naming.Assets }} / Type</th>
            <th>Last {{ Naming.Inspection }}</th>
            <th>Next {{ Naming.Inspection }}</th>
            <th>{{ Naming.Site }} / {{ Naming.Zone }}</th>
            <th>{{ Naming.Client }}</th>
            <th>Documents</th>
            <th>Risk Level</th>
            <th>Dynamic Statuses</th>
            <th>Current<br>Status</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="asset in assets.data" :key="asset.id">
            <td>
              <link-asset-manager class="has-text-weight-bold has-text-link" :asset="asset" :site-id="asset.zone.site_id"/>
              <br>
              <small>{{ asset.type ? asset.type.name : '--' }}</small>
            </td>
            <td>
              <latest-inspection :checklist-inspection-dates="asset.checklist_inspection_dates"></latest-inspection>
            </td>
            <td>
              <next-inspection :checklist-inspection-dates="asset.checklist_inspection_dates"></next-inspection>
            </td>
            <td>
              <strong>{{ asset.zone && asset.zone.site ? asset.zone.site.name : '--' }}</strong>
              <br>
              {{ asset.zone ? asset.zone.name : '--' }}
            </td>
            <td>{{ asset.zone.site.client.legal_name }}</td>
            <td>{{ asset.documents_count }}</td>
            <td class="is-narrow">{{ asset.risk.level }}</td>
            <td>
              <active-dynamic-statuses :active-statuses="asset.active_dynamic_statuses"/>
            </td>
            <td>
              <current-status :status="asset.current_status"/><br>
              <component-icon :asset="asset"/>
            </td>
          </tr>
        </tbody>
      </table>
      <pager :pageable="assets" @nav="goToPage"/>
    </div>
  </page>
</template>

<script>
import { common as backend } from '@/api'
import { mapGetters } from 'vuex'
import download from 'downloadjs'
import AssetFilters from '@/components/search/admin/AssetFilters'
import ActiveDynamicStatuses from "@/views/asset/partials/ActiveDynamicStatuses.vue";

export default {

  components: {
    ActiveDynamicStatuses,
    AssetFilters
  },

  data: () => ({
    loading: true,
    exporting: false,
    loadingAssets: false
  }),

  created() {
    this.loading = true
    this.loadAssets()
  },

  methods: {
    loadAssets() {
      this.loadingAssets = true
      this.$scrollToTop()
      backend.loadPath({
        path: '/api' + this.$route.fullPath,
      }, ({data}) => {
        this.$store.commit('asset/setAssets', data)
        this.loading = false
        this.loadingAssets = false
      }, error => {
        this.loading = false
        this.loadingAssets = false
      })
    },

    exportToFile(type) {
      this.exporting = true
      backend.downloadFile(
        `/api/assets/export/${type}`,
        response => {
          download(
            response.data,
            `Assets - ${this.$options.filters.date(new Date)}.${type}`,
            type === 'xlsx' ? 'application/vnd.ms-excel' : 'text/csv'
          )
          this.exporting = false
        },
        error => {
          console.error(error)
          this.exporting = false
          this.$whoops()
        },
        this.$route.query
      )
    },
    exportExcelReportWithChecks() {
      this.exporting = true
      backend.downloadFile(
        `/api/assets/export-with-checks`,
        response => {
          download(
            response.data,
            `Assets with checks - ${this.$options.filters.date(new Date)}.xlsx`,
            'application/vnd.ms-excel'
          )
          this.exporting = false
        },
        error => {
          console.error(error)
          this.exporting = false
          this.$whoops()
        },
        this.$route.query
      )
    },

    goToPage(path) {
      this.$router.push(path)
    },
  },

  computed: {
    ...mapGetters('asset', [
      'assets'
    ])
  },

  watch: {
    '$route': 'loadAssets'
  }

}
</script>
